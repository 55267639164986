import { InvestorFund, InvestorFundListItem } from '@gain/rpc/app-model'
import { isTruthy } from '@gain/utils/common'
import {
  Rating,
  ratingBuyAndBuild,
  ratingContracted,
  ratingConversion,
  ratingEbitda,
  ratingEsg,
  ratingEsgEnvironmental,
  ratingEsgOverall,
  ratingEsgSocial,
  ratingGrossMargin,
  ratingGrowth,
  RatingInvestmentCriteriaKey,
  ratingLeader,
  ratingMultinational,
  ratingNonCyclical,
  ratingOrganicGrowth,
  RatingTableCell,
} from '@gain/utils/investment-criteria'
import { investorFundExplanations } from '@gain/utils/investor-fund'
import { formatMultiple, formatNumber, formatPercentage } from '@gain/utils/number'
import { useMemo } from 'react'

import { FilterableInvestorFundListItem } from '../../../../../../libs/rpc/app-model/src/app-model-overrides'
import EbitdaValuesRange from '../../../common/ebitda-values-range'
import { ColumnConfig, createVirtualTableColumns } from '../../../common/virtual-table'
import {
  NameVirtualTableCell,
  NameVirtualTableHeaderCell,
} from '../../../common/virtual-table/cells'
import { TableCellFinancialAmount } from '../../../routes/investor/route-funds/table-cell-financial-amount'
import { AssetRegionsTableCell } from '../../asset/asset-regions-table-cell'
import { AssetSectorsTableCell } from '../../asset/asset-sectors-table-cell'
import FinancialValue from '../../financial/financial-value'
import InvestorContentLink from '../../investor/investor-content-link'
import { InvestorListItemAssetsTotalTableCell } from '../../investor/investor-list-item-table/investor-list-item-assets-total-table-cell'
import InvestorClassificationsTableCell from '../../investor-strategy/investor-strategy-table/investor-classifications-table-cell'
import useListViewColumnVisibilityModel from '../../list-view/use-list-view-column-visibility-model'

type FundPerformanceAmountKey = keyof Pick<
  InvestorFundListItem,
  'netIrr' | 'grossIrr' | 'twr' | 'tvpi' | 'moic' | 'dpi' | 'rvpi'
>

type FundPerformanceDateKey = keyof Pick<
  InvestorFund,
  'netIrrDate' | 'grossIrrDate' | 'twrDate' | 'tvpiDate' | 'moicDate' | 'dpiDate' | 'rvpiDate'
>

interface FundPerformanceColumn {
  field: FundPerformanceAmountKey
  dateField: FundPerformanceDateKey
  label: string
  helperText: string
  width: number
  formatAmount: (value: number | null) => string
}

type AssetsRatingKeys<T> = {
  [K in keyof T]: K extends `assetsMedianRating${string}` ? K : never
}[keyof T]

function createInvestmentCriteriaColumn(
  field: AssetsRatingKeys<InvestorFundListItem>,
  rating: Rating<RatingInvestmentCriteriaKey>
): ColumnConfig<InvestorFundListItem> {
  return {
    field,
    headerName: rating.label,
    headerExplainer: rating.explainer,
    align: 'left',
    width: 168,
    renderCell: (params) => (
      <RatingTableCell
        criteria={rating}
        rating={params.value}
        withHalfs
      />
    ),
  }
}

const fundPerformanceColumns = new Array<FundPerformanceColumn>(
  {
    field: 'netIrr',
    dateField: 'netIrrDate',
    label: 'Net IRR',
    helperText: investorFundExplanations.netIrr,
    width: 144,
    formatAmount: (value) => formatPercentage(value, { round: 2 }),
  },
  {
    field: 'grossIrr',
    dateField: 'grossIrrDate',
    label: 'Gross IRR',
    helperText: investorFundExplanations.grossIrr,
    width: 144,
    formatAmount: (value) => formatPercentage(value, { round: 2 }),
  },
  {
    field: 'twr',
    dateField: 'twrDate',
    label: 'TWR',
    helperText: investorFundExplanations.twr,
    width: 144,
    formatAmount: (value) => formatPercentage(value, { round: 2 }),
  },
  {
    field: 'tvpi',
    dateField: 'tvpiDate',
    label: 'TVPI',
    helperText: investorFundExplanations.tvpi,
    width: 144,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  },
  {
    field: 'moic',
    dateField: 'moicDate',
    label: 'MOIC',
    helperText: investorFundExplanations.moic,
    width: 144,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  },
  {
    field: 'dpi',
    dateField: 'dpiDate',
    label: 'DPI',
    helperText: investorFundExplanations.dpi,
    width: 144,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  },
  {
    field: 'rvpi',
    dateField: 'rvpiDate',
    label: 'RVPI',
    helperText: investorFundExplanations.rvpi,
    width: 144,
    formatAmount: (value) => formatMultiple(value, { round: 2 }),
  }
)

export const investorFundTableColumns = createVirtualTableColumns<InvestorFundListItem>(
  {
    field: 'name',
    headerName: 'Fund',
    align: 'left',
    width: 266,
    sticky: true,
    flex: 1,
    renderHeader: NameVirtualTableHeaderCell,
    renderCell: ({ row }) => (
      <NameVirtualTableCell
        description={[row.investorName, row.strategyName].filter(isTruthy).join(' - ')}
        name={row.name}
      />
    ),
  },
  {
    field: 'assetsTotal',
    headerName: 'Companies',
    align: 'right',
    width: 136,
    sortFields: ['assetsFiltered'],
    renderCell: (params) => <InvestorListItemAssetsTotalTableCell {...params} />,
  },
  {
    field: 'investorId',
    headerName: 'Investor',
    align: 'left',
    width: 272,
    renderCell: ({ row }) => (
      <InvestorContentLink
        id={row.investorId}
        logoFileUrl={row.investorLogoFileUrl}
        name={row.investorName}
      />
    ),
  },
  {
    field: 'strategyClassifications',
    headerName: 'Strategy classification',
    width: 257,
    renderCell: ({ row }) => (
      <InvestorClassificationsTableCell classifications={row.strategyClassifications} />
    ),
  },
  {
    field: 'fundSizeEur',
    headerName: 'Fund size',
    align: 'right',
    width: 128,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'fundraisingStatus',
    headerName: 'Fundraising status',
    width: 192,
  },
  {
    field: 'vintageYear',
    headerName: 'Vintage date',
    align: 'right',
    width: 130,
    valueFormatter: ({ value }) => value || '-',
  },
  {
    field: 'assetEbitdasEur',
    headerName: 'EBITDA range',
    width: 208,
    sortable: false,
    renderCell: ({ value }) => <EbitdaValuesRange ebitdaValues={value} />,
  },
  {
    field: 'assetEbitdaMedianEur',
    headerName: 'Median EBITDA',
    headerExplainer:
      'Median EBITDA is calculated using only positive EBITDA values, to give a better representation of typical investment size',
    align: 'right',
    width: 160,
    sortFields: ['assetEbitdaMedianEur'],
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'assetRegions',
    headerName: 'Active in region',
    width: 350,
    sortable: false,
    renderCell: ({ row }) => (
      <AssetRegionsTableCell
        maxItems={4}
        regionNames={row.assetRegions}
        disableCounts
      />
    ),
  },
  {
    field: 'assetSectors',
    headerName: 'Active in sector',
    width: 350,
    sortable: false,
    renderCell: ({ row }) => (
      <AssetSectorsTableCell
        maxItems={2}
        sectorNames={row.assetSectors}
      />
    ),
  },
  {
    field: 'dealsEntriesTotalLastFiveYears',
    headerName: 'Entries (L5Y)',
    align: 'right',
    width: 128,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: 'dealsExitTotalLastFiveYears',
    headerName: 'Exits (L5Y)',
    align: 'right',
    width: 128,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: 'dealsAddOnsTotalLastFiveYears',
    headerName: 'Add-ons (L5Y)',
    align: 'right',
    width: 128,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  {
    field: 'dealsTotalLastFiveYears',
    headerName: 'Total deals (L5Y)',
    align: 'right',
    width: 160,
    valueFormatter: ({ value }) => formatNumber(value),
  },
  createInvestmentCriteriaColumn('assetsMedianRatingGrowth', ratingGrowth),
  createInvestmentCriteriaColumn('assetsMedianRatingOrganicGrowth', ratingOrganicGrowth),
  createInvestmentCriteriaColumn('assetsMedianRatingGrossMargin', ratingGrossMargin),
  createInvestmentCriteriaColumn('assetsMedianRatingEbitda', ratingEbitda),
  createInvestmentCriteriaColumn('assetsMedianRatingConversion', ratingConversion),
  createInvestmentCriteriaColumn('assetsMedianRatingNonCyclical', ratingNonCyclical),
  createInvestmentCriteriaColumn('assetsMedianRatingContracted', ratingContracted),
  createInvestmentCriteriaColumn('assetsMedianRatingLeader', ratingLeader),
  createInvestmentCriteriaColumn('assetsMedianRatingMultinational', ratingMultinational),
  createInvestmentCriteriaColumn('assetsMedianRatingBuyAndBuild', ratingBuyAndBuild),
  {
    field: 'assetsMedianMarketSegmentRatingOverall',
    headerName: ratingEsgOverall.label,
    headerExplainer: ratingEsgOverall.explainer,
    align: 'left',
    width: 168,
    renderCell: (params) => (
      <RatingTableCell
        criteria={ratingEsg}
        rating={params.value}
        withHalfs
      />
    ),
  },
  {
    field: 'assetsMedianMarketSegmentRatingEnvironmental',
    headerName: ratingEsgEnvironmental.label,
    headerExplainer: ratingEsgEnvironmental.explainer,
    align: 'left',
    width: 185,
    renderCell: (params) => (
      <RatingTableCell
        criteria={ratingEsg}
        rating={params.value}
        withHalfs
      />
    ),
  },
  {
    field: 'assetsMedianMarketSegmentRatingSocial',
    headerName: ratingEsgSocial.label,
    headerExplainer: ratingEsgSocial.explainer,
    align: 'left',
    width: 168,
    renderCell: (params) => (
      <RatingTableCell
        criteria={ratingEsg}
        rating={params.value}
        withHalfs
      />
    ),
  },
  ...fundPerformanceColumns.map((config) => ({
    field: config.field,
    headerName: config.label,
    width: config.width,
    headerExplainer: config.helperText,
    renderCell: ({ row }) => (
      <TableCellFinancialAmount
        amount={row[config.field]}
        date={row[config.dateField]}
        formatAmount={config.formatAmount}
      />
    ),
  }))
)

export const investorFundTableColumnNames = investorFundTableColumns
  .map(({ field }) => field)
  .filter(Boolean) as (keyof InvestorFundListItem)[]

export default function useInvestorFundListViewColumns(
  activeFilterColumns: (keyof FilterableInvestorFundListItem)[]
) {
  const visibleColumns = useListViewColumnVisibilityModel(
    'investorFund',
    investorFundTableColumnNames,
    activeFilterColumns
  )

  return useMemo(() => {
    return investorFundTableColumns.filter(({ field }) => visibleColumns[field])
  }, [visibleColumns])
}
