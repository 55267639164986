import { AssetList, InvestorStrategy } from '@gain/rpc/app-model'
import * as Shared from '@gain/rpc/shared-model'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import ExternalLink from '../../../common/external-link'
import KeyValueList, { KeyValueListItem } from '../../../common/key-value/key-value-list'
import { useInvestorStrategyMedianEbitda } from '../../../routes/investor-strategy/route-summary/investor-strategy-metrics-card/investor-strategy-metrics-card-hooks'
import FinancialLegendChip from '../../financial/financial-results-table/financial-legend-chip'
import {
  useInvestorStrategyMedianRevenue,
  useInvestorStrategyPreferredStake,
} from './investor-strategy-preferences-card-hooks'

interface InvestorStrategyInformationCardProps {
  strategy: InvestorStrategy
  assets?: AssetList
}

export default function InvestorStrategyPreferencesCard({
                                                          strategy,
                                                          assets,
                                                        }: InvestorStrategyInformationCardProps) {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  const investmentTicketSizeRange = formatCurrencyRange(
    strategy.investmentTicketSizeMin,
    strategy.investmentTicketSizeMax,
    { dataCurrency: strategy.currency }
  )
  const evRange = formatCurrencyRange(strategy.evRangeMin, strategy.evRangeMax, {
    dataCurrency: strategy.currency,
  })
  const revenueRange = formatCurrencyRange(strategy.revenueRangeMin, strategy.revenueRangeMax, {
    dataCurrency: strategy.currency,
  })
  const ebitdaRange = formatCurrencyRange(strategy.ebitdaRangeMin, strategy.ebitdaRangeMax, {
    dataCurrency: strategy.currency,
  })
  const preferredStake = useInvestorStrategyPreferredStake(strategy, assets?.items)

  const medianRevenue = useInvestorStrategyMedianRevenue(assets)
  const medianEbitda = useInvestorStrategyMedianEbitda(assets)

  return (
    <Card
      sx={{ pb: 0 }}
      fullHeight>
      <CardHeader title={'Investment preferences'} />
      <CardContent>
        <KeyValueList>
          {(revenueRange || (!revenueRange && medianRevenue === null)) && (
            <KeyValueListItem
              label={'Revenue range'}
              value={revenueRange || 'Not reported'}
              valueTypographyProps={{ color: revenueRange ? 'text.primary' : 'text.secondary' }}
            />
          )}

          {!revenueRange && medianRevenue && (
            <KeyValueListItem
              label={'Median Revenue'}
              value={medianRevenue}
              valueTypographyProps={{ color: 'info.main' }}
            />
          )}

          {(ebitdaRange || (!ebitdaRange && medianEbitda === null)) && (
            <KeyValueListItem
              label={'EBITDA range'}
              value={ebitdaRange || 'Not reported'}
              valueTypographyProps={{ color: ebitdaRange ? 'text.primary' : 'text.secondary' }}
            />
          )}

          {!ebitdaRange && medianEbitda && (
            <KeyValueListItem
              label={'Median EBITDA'}
              value={medianEbitda}
              valueTypographyProps={{ color: 'info.main' }}
            />
          )}

          <KeyValueListItem
            label={'Preferred equity stake'}
            value={preferredStake.stake || 'Not reported'}
            valueTypographyProps={{
              color: preferredStake.color,
            }}
          />

          <KeyValueListItem
            label={'Investment ticket size'}
            value={investmentTicketSizeRange || 'Not reported'}
            valueTypographyProps={{
              color: investmentTicketSizeRange ? 'text.primary' : 'text.secondary',
            }}
          />

          <KeyValueListItem
            label={'EV range'}
            value={evRange || 'Not reported'}
            valueTypographyProps={{ color: evRange ? 'text.primary' : 'text.secondary' }}
          />

          {strategy.source && (
            <KeyValueListItem
              label={'Source'}
              value={<ExternalLink href={strategy.source}>Link</ExternalLink>}
            />
          )}
        </KeyValueList>
      </CardContent>

      <Divider sx={{ mt: 1 }} />
      <Stack
        alignItems={'center'}
        direction={'row'}
        height={48}
        justifyContent={'center'}
        spacing={0.5}>
        <FinancialLegendChip
          amountType={Shared.FinancialResultAmountType.Actual}
          label={'Reported'}
        />

        <FinancialLegendChip
          amountType={Shared.FinancialResultAmountType.Estimated}
          label={'Estimate'}
        />
      </Stack>
    </Card>
  )
}
