import {
  ActivityIcon,
  BriefcaseIcon,
  CompanyIcon,
  LeafIcon,
  ShuffleIcon,
  StarOutlinedIcon,
} from '@gain/components/icons'
import { SECTORS, SUBSECTORS } from '@gain/utils/sector'

import { investorFundTableColumns } from '../investor-fund/investor-fund-table/use-investor-fund-list-view-columns'
import { TopLevelColumnGroup } from './column-picker-model'
import { fromConfig, getRegionAndCountrySearchAliases } from './column-picker-util'

export const columnPickerConfigInvestorFund = new Array<TopLevelColumnGroup>(
  {
    name: 'Key facts',
    Icon: CompanyIcon,
    columns: [
      fromConfig(investorFundTableColumns, 'name', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(investorFundTableColumns, 'assetsTotal', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(investorFundTableColumns, 'investorId', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(investorFundTableColumns, 'strategyClassifications', {
        visibleByDefault: true,
      }),
      fromConfig(investorFundTableColumns, 'fundSizeEur', {
        visibleByDefault: true,
      }),
      fromConfig(investorFundTableColumns, 'vintageYear', {
        visibleByDefault: true,
      }),
    ],
  },
  {
    name: 'Portfolio',
    Icon: BriefcaseIcon,
    columns: [
      fromConfig(investorFundTableColumns, 'assetEbitdasEur', {
        visibleByDefault: true,
      }),
      fromConfig(investorFundTableColumns, 'assetEbitdaMedianEur'),
      fromConfig(investorFundTableColumns, 'assetRegions', {
        visibleByDefault: true,
        searchAliases: getRegionAndCountrySearchAliases(),
      }),
      fromConfig(investorFundTableColumns, 'assetSectors', {
        visibleByDefault: true,

        searchAliases: [
          ...SECTORS.map((value) => value.title),
          ...SUBSECTORS.map((value) => value.title),
        ],
      }),
    ],
  },
  {
    name: 'Deals',
    Icon: ShuffleIcon,
    columns: [
      fromConfig(investorFundTableColumns, 'dealsEntriesTotalLastFiveYears'),
      fromConfig(investorFundTableColumns, 'dealsExitTotalLastFiveYears'),
      fromConfig(investorFundTableColumns, 'dealsAddOnsTotalLastFiveYears'),
      fromConfig(investorFundTableColumns, 'dealsTotalLastFiveYears', {
        visibleByDefault: true,
      }),
    ],
  },
  {
    name: 'Portfolio assessment',
    Icon: StarOutlinedIcon,
    columns: [
      fromConfig(investorFundTableColumns, 'assetsMedianRatingGrowth'),
      fromConfig(investorFundTableColumns, 'assetsMedianRatingOrganicGrowth'),
      fromConfig(investorFundTableColumns, 'assetsMedianRatingGrossMargin'),
      fromConfig(investorFundTableColumns, 'assetsMedianRatingEbitda'),
      fromConfig(investorFundTableColumns, 'assetsMedianRatingConversion'),
      fromConfig(investorFundTableColumns, 'assetsMedianRatingNonCyclical'),
      fromConfig(investorFundTableColumns, 'assetsMedianRatingContracted'),
      fromConfig(investorFundTableColumns, 'assetsMedianRatingLeader'),
      fromConfig(investorFundTableColumns, 'assetsMedianRatingMultinational'),
      fromConfig(investorFundTableColumns, 'assetsMedianRatingBuyAndBuild'),
    ],
  },
  {
    name: 'ESG',
    Icon: LeafIcon,
    columns: [
      fromConfig(investorFundTableColumns, 'assetsMedianMarketSegmentRatingOverall'), // Overall ESG risk
      fromConfig(investorFundTableColumns, 'assetsMedianMarketSegmentRatingEnvironmental'), // Environmental risk
      fromConfig(investorFundTableColumns, 'assetsMedianMarketSegmentRatingSocial'), // Social risk
    ],
  },
  {
    name: 'Fund performance',
    Icon: ActivityIcon,
    columns: [
      fromConfig(investorFundTableColumns, 'netIrr'),
      fromConfig(investorFundTableColumns, 'grossIrr'),
      fromConfig(investorFundTableColumns, 'twr'),
      fromConfig(investorFundTableColumns, 'tvpi'),
      fromConfig(investorFundTableColumns, 'moic'),
      fromConfig(investorFundTableColumns, 'dpi'),
      fromConfig(investorFundTableColumns, 'rvpi'),
    ],
  }
)
